import './App.css';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import axios from 'axios';

const turnover_api = axios.create({
  baseURL: 'https://turnover.api.mailing.prisme-assurances.net',
  //baseURL: 'http://localhost:3060',
  timeout: 36000,
});

const contracts_api = axios.create({
  baseURL: 'https://api.backoffice.mailing.prisme-assurances.net/contracts',
  //baseURL: 'http://localhost:3010/contracts',
  timeout: 36000,
});

const uri = window.location.href;
const token = uri.split('/').at(-1);

function App() {
  const [address_changed, set_address_changed] = useState(false);
  const [contract, set_contract] = useState(null);
  const [already_submitted, set_already_submitted] = useState(false);

  useEffect(() => {

    async function fetch_contracts_api() {
      try {
        const result = await contracts_api.get(token, { headers: { 'Authorization': `token ${process.env.REACT_APP_PRISME_MAILING_API_TOKEN}` } });
        set_contract(result.data.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetch_contracts_api();

  }, []);

  useEffect(() => {

    async function check_if_already_submitted() {

      try {
        const result = await turnover_api.post(`/forms/search?year=${year}&customer_code=${contract.recipient.customer_code}`);
        
        if(result.data.exists){
          set_already_submitted(true);
        }
        
      } catch (error) {
        console.error(error);
        set_already_submitted(false);
      }

    }

    if (contract)
      check_if_already_submitted();

  }, [contract]);

  const year = new Date().getFullYear() - 1;

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data, e) => {

    data.customer_code = contract.recipient.customer_code;
    data.address_changed = address_changed;
    data.token = token;
    data.created_at = new Date().toISOString().slice(0, 19).replace('T', ' ');//format JS date to SQL date

    try {
        await turnover_api.post('/forms', data);
      
        set_already_submitted(true);

      e.target.reset();
    } catch (error) {
      set_already_submitted(false);
      alert("Une erreur s'est produite, votre déclaration n'a pas été enregistrée. Veuillez nous contacter.");
    }
  }

  const [submitResult, setSubmitResult] = useState(null);

  function pdf_form_link(){
    return `https://turnover.api.mailing.prisme-assurances.net/forms/pdf/${token}`;
  }

  return (
    <div className="App">
      <main className="wrapper">
        <section className="container">

          {already_submitted || !contract ? null :
            <div className='row'>
              <div className="column">
                <img alt="logo Prisme Assurances" className="logo " src="/images/logo-prisme-assurances.png" />
              </div>
              <div className='column customer-data'>
                <p>{contract.recipient.firstname} {contract.recipient.lastname}</p>
                <p>N° de client : {contract.recipient.customer_code} - N° de police d'assurance : {contract.customerdata.police_code}</p>
                <p>Email : {contract.recipient.email}</p>
                <p>Adresse : {contract.customerdata.address1}</p>
                <p>Complément d'adresse : {contract.customerdata.address2}</p>
                <p>Code postal : {contract.customerdata.zip_code} - Ville : {contract.customerdata.city}</p>
                <p>N° de téléphone mobile : {contract.customerdata.mobile_phone}</p>
                <p>N° de téléphone 1 : {contract.customerdata.phone1}</p>
                <p>N° de téléphone 2 : {contract.customerdata.phone2}</p>
              </div>
            </div>
          }
          <h1>2ème Rappel Demande de Chiffre d’Affaires <strong>{year}</strong></h1>

          {contract === null && <div className='notification error'><p>Désolé, nous ne sommes pas parvenus à identifier votre contrat. Veuillez nous contacter directement svp.</p></div>}

          {already_submitted === true &&
          <div className='notification done'>

            <p>
            Vous avez effectué votre déclaration de chiffre d'affaires pour l'exercice {year}. Merci.
          </p>
          </div>
          }

            
          {contract !== null && !already_submitted && 
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="row">

                <div className='column'>

                  <p>
                    Vous pouvez renseigner directement ce formulaire en ligne ou <a target="_blank" href={pdf_form_link()}>télécharger la version PDF</a> à remplir, signer et retourner.
                  </p>

                  <div className="column">

                    <div>
                      <p>
                        Conformément aux conditions de votre contrat RC MARCHAND DE BIENS, nous vous remercions de bien vouloir nous communiquer les renseignements demandés ci-dessous :
                      </p>

                    </div>

                    <div className="column">
                      <label>Montant du chiffre d'affaires HT pour l'exercice {year} en tant que <strong>lotisseur</strong> :</label>

                      <div className='row'>
                        <input pattern="[0-9]" minLength="1" type="number" {...register("ca_lotisseur", { required: false, minLength: 1, value: 0 })} />
                        <span>€</span>
                      </div>
                      {errors.ca_lotisseur && <span className="error">Chiffres d'affaires lotisseur incorrect</span>}

                    </div>
                    <div className="column">
                      <label>Montant du chiffre d'affaires HT pour l'exercice {year} en tant que <strong>marchand de biens</strong> :</label>
                      <div className='row'>
                        <input pattern="[0-9]" minLength="1" type="number" {...register("ca_marchand_de_biens", { required: false, minLength: 1, value: 0 })} />
                        <span>€</span>
                      </div>
                      {errors.ca_marchand_de_biens && <span className="error">Chiffre d'affaires marchand de biens incorrect</span>}
                    </div>

                    <div>
                      <p>(le montant du chiffre d’affaires correspond au montant des ventes)</p>
                    </div>

                    <div>
                      <label>Nouvelles coordonnées depuis <strong>{year}</strong> ?</label>
                      <input type="checkbox" default={address_changed} onChange={() => set_address_changed(address_changed ? false : true)} />
                      <span>oui</span>
                    </div>

                    {(submitResult) ? <div className="notification submit-success">Votre déclaration a été envoyée avec succès. Merci.</div> : null}

                    <input value="Envoyer" type="submit" />
                  </div>
                </div>

                <div className='column' style={address_changed ? { display: "block" } : { display: "none" }}>
                  <h1>Coordonnées</h1>

                  <div className="column">

                    <p>Afin de nous permettre d’actualiser les renseignements vous concernant, merci de bien vouloir remplir le formulaire ci-dessous :</p>
                    
                    <div className="column">
                      <label>Adresse : </label>
                      {!contract ? null :
                        <input type="text" {...register("address1", { required: false, value: contract.customerdata.address1 })} />
                      }
                      {errors.address1 && <span className="error">Adresse incorrecte</span>}
                    </div>

                    <div className="column">
                      <label>Complément d'adresse : </label>
                      {!contract ? null :
                        <input type="text" {...register("address2", { required: false, value: contract.customerdata.address2 })} />
                      }
                      {errors.address2 && <span className="error">Adresse complémentaire incorrecte</span>}
                    </div>

                    <div className="column">
                      <label>Code postal : </label>
                      {!contract ? null :
                        <input type="text" {...register("zip_code", { required: false, value: contract.customerdata.zip_code })} />
                      }
                      {errors.zip_code && <span className="error">Code postal incorrect</span>}
                    </div>

                    <div className="column">
                      <label>Ville : </label>
                      {!contract ? null :
                        <input type="text" {...register("city", { required: false, value: contract.customerdata.city })} />
                      }
                      {errors.city && <span className="error">Ville incorrecte</span>}
                    </div>

                    <div className="column">
                      <label>Email : </label>
                      {!contract ? null :
                        <input type="email" {...register("email", { required: false, value: contract.recipient.email })} />
                      }
                      {errors.email && <span className="error">Email incorrect</span>}
                    </div>

                    <div className="column">
                      <label>N° de téléphone mobile : </label>
                      {!contract ? null :
                        <input type="text" {...register("mobile_phone", { required: false, value: contract.customerdata.mobile_phone })} />
                      }
                      {errors.mobile_phone && <span className="error">N° de téléphone portable incorrect</span>}
                    </div>

                    <div className="column">
                      <label>N° de téléphone 1 : </label>
                      {!contract ? null :
                        <input type="text" {...register("phone1", { required: false, value: contract.customerdata.phone1 })} />
                      }
                      {errors.phone1 && <span className="error">N° de téléphone 1 incorrect</span>}
                    </div>

                    <div className="column">
                      <label>N° de téléphone 2 : </label>
                      {!contract ? null :
                        <input type="text" {...register("phone2", { required: false, value: contract.customerdata.phone2 })} />
                      }
                      {errors.phone2 && <span className="error">N° de téléphone 2 incorrect</span>}
                    </div>

                  </div>

                </div>

              </div>
            </form>

          }

          <footer className='column'>
            <hr />
            <p>
              <strong>PRISME</strong>
              <br />
              11 bis Avenue de Boufflers - BP 93619
              <br />
              54098 NANCY CEDEX
              <br />
              Tél. 03 83 40 84 16
              <br />
              Fax 03 83 40 84 11
              <br />
              info@prisme-assurances.com
              <br />
              SAS au capital de 210 000 € - RCS NANCY 323 427 922
            </p>
            <p>

              Enregistré à l'Orias sous le n° 07027163  (www.orias.fr) en qualité de courtier en assurances
              (exerçant sous les modalités du b) de l’article L.520-1 II du code des assurances)
              - Autorité de Contrôle Prudentiel et de Résolution : ACPR – 4 Place de Budapest CS 92459 - 75436 Paris
              <br />

              Service Réclamation : PRISME - 11 bis avenue de Boufflers BP 93619 - 54098 NANCY CEDEX - 03.83.40.84.10
              <br />
              <br />

              Conformément aux articles 39 et suivants de la loi n° 78-17 du 6 janvier 1978 modifiée en 2004 relative à l’informatique, aux fichiers et aux libertés,
              <br />

              toute personne peut obtenir communication et, le cas échéant, rectification ou suppression des informations la concernant, en s’adressant à PRISME.
            </p>
            <div className='center'>v. 20220725-1845</div>
          </footer>

        </section>
      </main>
    </div >
  );
}

export default App;
